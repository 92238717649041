<template>
  <b-row>
    <b-col cols="12">
      <div class="d-flex justify-content-end my-1">
        <b-button variant="primary" to="/proforma/add">
          <span class="text-nowrap">Add Proforma</span>
        </b-button>
      </div>
    </b-col>
    <!-- search input -->
    <b-col md="2" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50">Per page</label>
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
      </b-form-group>
    </b-col>

    <b-col md="10" class="my-1">
      <b-form-group label="Filter" label-cols-sm="8" label-align-sm="right" label-size="sm" label-for="filterInput"
        class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="salesOrderList"
        :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
        :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(isActive)="data">
          <b-badge v-if="data.item.isActive" variant="primary"> Active </b-badge>
          <b-badge v-else variant="danger"> Inactive </b-badge>
        </template>
        <template #cell(total)="data"> Rs {{ data.item.total.toFixed(2) }} </template>
        <template #cell(salesPersonId)="data">
          <p v-if="data.item.salesPersonId === null">-</p>
          <p v-else>{{ getSalePersonName(data.item.salesPersonId) }}</p>
        </template>
        <template v-slot:cell(issuedDate)="data">
          {{ convertTimestampToDate(data.item.issuedDate) }}
        </template>
        <template #cell(orderType)="data">
          <b-badge v-if="data.item.orderType === 0" variant="info"> Fixed Order </b-badge>
          <b-badge v-else-if="data.item.orderType === 1" variant="primary">
            Variable Order
          </b-badge>
          <b-badge v-else-if="data.item.orderType === 2" variant="secondary">
            OneOff Order
          </b-badge>
          <b-badge v-else-if="data.item.orderType === 3" variant="warning">
            Cancelled
          </b-badge>
        </template>
        <template #cell(createdOn)="data">
          {{ convertTimestampToDate(data.item.createdOn) }}
        </template>
        <template #cell(status)="data">
          <b-badge v-if="data.item.status === 0" variant="secondary"> PROFORMA </b-badge>
          <b-badge v-else-if="data.item.status === 1" variant="warning">
            ONDEMAND
          </b-badge>
          <b-badge v-else-if="data.item.status === 2" variant="info">
            READY
          </b-badge>
          <b-badge v-else-if="data.item.status === 3" variant="info">
            INVOICED
          </b-badge>
          <b-badge v-else-if="data.item.status === 4" variant="danger">
            CANCELLED
          </b-badge>
          <b-badge v-else variant="danger"> Blocked </b-badge>
        </template>
        <template #cell(actions)="data">
          <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
            <template v-slot:button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
            </template>
            <b-dropdown-item :to="getSalesOrderIdView(data.item.id)">
              <feather-icon icon="EyeIcon" class="mr-50" />
              <span>View</span>
            </b-dropdown-item>
            <b-dropdown-item :to="getSalesOrderId(data.item.id)" v-if="data.item.status === 0">
              <feather-icon icon="Edit2Icon" class="mr-50" />
              <span>Edit</span>
            </b-dropdown-item>
            <b-dropdown-item @click="removesalesOrder(data.item)"
              v-if="data.item.status === 0 || data.item.status === 1">
              <feather-icon icon="TrashIcon" class="mr-50" />
              <span>Cancel</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="d-flex justify-content-center mb-1">
        <b-alert variant="warning" show v-if="salesOrderList.length === 0" class="d-flex justify-content-center">
          <h4 class="alert-heading">No Proformas yet</h4>
        </b-alert>
      </div>
      <div class="d-flex justify-content-center mb-1">
        <b-spinner variant="success" label="Spinning" block v-if="loading" style="width: 3rem; height: 3rem"></b-spinner>
      </div>
    </b-col>

    <b-col cols="12">
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
        class="my-0" />
        <b-form-group>
      Showing {{ perPage * (currentPage - 1) + 1}} 
      to {{ Math.min(perPage * currentPage, totalRows) }} 
      of {{ totalRows }} entries
        </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BAlert,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BSpinner,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";

import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BAlert,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BSpinner,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      idLead: this.$route.params.id,
      leadClientName: "",
      total: "",
      issuedDate: "",
      orderType: "",
      isActive: 1,
      status: "",
      orderNumber: "",
      salesOrderData: [],
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 0,
      currentPage: 0,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        { key: "orderNumber", label: "PROFORMA NUMBER" },
        { key: "leadClientName", label: "CUSTOMER" },
        { key: "total", label: "TOTAL (RS)" },
        { key: "issuedDate", label: "ISSUE DATE", sortable: true },
        { key: "orderType", label: "ORDER TYPE" },

        { key: "salesPersonId", label: "Sales Person", sortable: true },
        { key: "createdOn", label: "Created On", sortable: true },
        { key: "status", label: "STATUS" },
        "actions",
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    ...mapGetters("salesPersonModule", {
      SalesPersonList: "salesperson",
      loading: "loading",
    }),

    ...mapGetters("salesOrderModule", {
      salesOrderList: "salesorders",
      loading: "loading",
    }),

    ...mapGetters("leadModule", {
      leadId: "leadId",
      //salesOrderList2: "salesorders",
    }),
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.salesOrderList.length;
  },
  methods: {
    ...mapActions("salesPersonModule", ["getSalesPersonListAction"]),
    getSalePersonName(id) {
      const salesPerson = this.SalesPersonList.find((s) => s.id === id);
      return salesPerson ? salesPerson.firstName + " " + salesPerson.lastName : "";
    },
    convertTimestampToDate(timestamp) {
      return new Date(timestamp).toLocaleDateString();
    },
    getSalesOrderIdView(data) {
      return "/proforma/view/" + data;
    },
    getsalesOrderData(data) {
      console.log(data);
      this.salesOrderData = data;
    },
    getSalesOrderId(item) {
      return "/proforma/edit/" + item;
    },

    removesalesOrder(item) {
      let payload = {
        id: item.id,
        orderNumber: item.orderNumber,
        leadClientId: item.leadClientId,
        salesPersonId: item.salesPersonId,
        issuedDate: item.issuedDate,
        dueDate: item.dueDate,
        note: item.note,
        paymentMethod: item.paymentMethod,
        status: 4,
        orderType: item.orderType,
        subTotal: item.subTotal,
        discount: item.discount,
        tax: item.tax,
        total: item.total,
        orderItems: item.orderItems,
      }
      this.updateSalesOrdersAction(payload).then(() => {
        this.$swal({
          icon: "success",
          title: "Proforma cancelled successfully",
          customClass: {
            confirmButton: "btn btn-success",
          },
        })
        this.getSalesOrdersListAction();
      })
        .catch((error) => {
          this.error(error);
        });

    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    ...mapActions("salesOrderModule", [
      "getSalesOrdersListAction",
      "removeSalesOrdersAction",
      "updateSalesOrdersAction",
    ]),
    ...mapActions("leadModule", ["getLeadQuotationsAction"]),
  },
  async mounted() {
   
    await this.getSalesOrdersListAction()
      .then(() => {
        console.log("this.salesOrderList" + this.salesOrderList);
        this.totalRows = this.salesOrderList.length;
      })
      .catch((response) => {
        console.log(response);
      });
      await this.getSalesPersonListAction();
  },
};
</script>
